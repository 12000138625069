.parameter-control {

    &__on-action {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.6rem;
        animation: sync-rotate-anim 1.3s linear infinite;
        transition: all 0.2s;


        @keyframes sync-rotate-anim {
            0% {
                transform: rotate(0deg);
            }
            50% {
                transform: rotate(180deg);
            }
            100% {
                transform: rotate(360deg);
            }

        }
        svg {
            margin: 0;
            font-weight: bold;
            transform: scale(1.4);
        }
    }
    &__sync-button {
        opacity: 0;
        height: 0;
        width: 0;
        padding: 0;
        margin: 0;
    }

    &__sync-feedback {
        font-size: 1rem;
        font-weight: bolder;
        text-shadow: 0.1px 0.1px 0.1px rgba(0, 0, 0, 0.199);
        position: absolute;
        transform: translateY(10px);
    }
    &__sync-feedback--success {
        color: $success;
    }
    &__sync-feedback--faild {
        color: $error;
    }
}