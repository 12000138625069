.table-component {
    font-family: $font-family_he_v1;
    background-color: #E7EBF0;
    padding: 1.2rem 1.5rem;
    border-radius: 10px;

    &__table {
        border-radius: 5px;
        // overflow: hidden;
        box-shadow: 1px 2px 2px #a0a0a0;
        background-color: #ffffff;
       
        &__thead {
            background-color: #FFFFFF;
            tr {
                border: 1px solid #f1f4f7;
                th {
                   padding: 1rem;
                   text-align: right;
                   font-weight: bold;
                   font-size: 16px;
                }
            }
        }
    
        &__tbody {
            tr {
                border: 1px solid #e3e7eb;
    
                &:hover {
                    background-color: #eff2f7;
                }
                td {
                    padding: 1rem;
                    font-size: 16px;
                    position: relative;
                }
            }
        }
    }
}