.modal-component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    font-family: $font-family_he_v1;

    
    &__outline {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $backgound_screen;
        z-index: -1;
        .exit-btn {
            position: absolute;
            top: 4vw;
            right: 4vw;
            width: 2rem;
            height: 2rem;
            z-index: 9999;
            transform: scale(4);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            &:hover {
                opacity: 0.85;
            }
            &:active {
                opacity: 1;
                transform: scale(3.5);
            }
        }
    }
    &__inline {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 95%;
        max-width: 1000px;
        height: 80%;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        z-index: 1;
        border-radius: 8px;
        overflow: hidden;
        color: $text_color;

        svg {
            margin-right: 0.5rem;
            transform: scale(1.1);
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.493));
        }

        animation: test 0.3s ease-in-out;
        @keyframes test {
            0% {
                opacity: 0;
                transform: translateY(-5rem);
            }
            100% {
                opacity: 1;
                transform: translateY(0px);
            }
        }

        .modal-header {
            box-shadow: inset 0 5px 15px rgba(255, 255, 255, 0.164);
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.493);
            font-size: 1.2rem;
            filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.212));
            direction: rtl;

        }
        .--modal-UPDATE {
            background-color: $primary;
        }
        .--modal-CREATE {
            background-color: $success;
        }


        .modal-body {
            .submit-btn {
                position: absolute;
                bottom: 1rem;
                left: 0;
                right: 0;
                margin: auto;
                width: max-content;
            }
        }
    }

}