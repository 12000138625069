// colors ----------------------
// @ User indication
$success: #1B9E43;
$warning: #ff9800;
$lucky: #8250DF;
$error: #ef5350;
$primary: #0275D8;
$info: #24baff;
$secondary: #aaaaaa;
$settings: #607d8b;


// @ typography 
$text_color: #8F939A;
// @ App Setting 
$backgound_screen: rgba(0, 0, 0, 0.637);


// fonts -----------------------
$font-family_he_v1: 'Roboto', sans-serif;
