body {
    overflow: auto;
    margin-bottom: 3rem;
    font-family: $font-family_he_v1;
    /* width */
&::-webkit-scrollbar {
  width: 10px;
}

/* Track */
&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  // border-radius: 10px;
}
 
/* Handle */
&::-webkit-scrollbar-thumb {
  background: $secondary; 
  // border-radius: 10px;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: $secondary; 
}
  }
  
.App {
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;

  header {
    position: relative;
    .logo-nav-bar {
      display: flex;
      position: absolute;
      height: 3rem;
      top: 0;
      bottom: 0;
      left: 3.5vw;
      margin: auto;
  }
  
  }
}
  