.button-component {
    color: #FFFFFF;
    background-color: $success;
    outline: none;
    border: none;
    font-size: 15px;
    text-align: center;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    box-shadow: inset 0 5px 7px rgba(255, 255, 255, 0.178);
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        margin-right: 0.4rem;
    }


    &:hover {
        opacity: 0.85;
    }
    &:active {
        opacity: 1;
    }

    &:disabled {
        opacity: 0.4;
        color: #2b2b2b;
        filter: grayscale(1);
    }

    &__disabledEffect-false {
        &:disabled {
            opacity: 1;
            color: #FFFFFF;
            filter: unset;
        }
    }

}


.bc--success {
    background-color: $success;
}
.bc--warning {
    background-color: $warning;
}
.bc--lucky {
    background-color: $lucky;
}
.bc--error {
    background-color: $error;
}
.bc--primary {
    background-color: $primary;
}
.bc--info {
    background-color: $info;
}
.bc--secondary {
    background-color: $secondary;
}
.bc--settings {
    background-color: $settings;
}


