
.nav-bar {
    background-image: linear-gradient(to top, #c80308, #d0130f, #d71d16, #df261c, #e72e22);
    box-shadow: 0 0.1rem 2rem #afafaf;
    padding: 1rem 3.5vw;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;


    &__nav-btn {
        margin-left: 0.5rem;
        padding: 0.5rem .6rem;
        border-radius: 0.2rem;
        transition: all 0.15s;
        color: whitesmoke;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        text-decoration: none;
        font-weight: bold;

        &:hover {
            background-color: rgba(0, 0, 0, 0.257);
        }
        &:active {
            background-color: rgba(0, 0, 0, 0.501);
            transition: all 0s;
        }
    }

    &__home-page {
        background-color: #fff;
        color: rgb(34, 34, 34);
        transition: all 0.25s ease-in-out;
        padding: 0.5rem 1rem;
        margin-left: 1rem;

        &:hover {
            background-color: #fff;
            box-shadow: 0 0 0.4rem white;
        }
        &:active {
            background-color: #fff;
        }
    }
}
