.details-component {
    background-color: rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
    max-width: 20rem;
    padding: 0.8rem;
    border-radius: 0.4rem;
    border: 0.5px solid rgb(173, 173, 173);
    font-size: 0.8rem;

    &>:nth-child(n) {
        line-height: 1.2rem;
    }

    &__title {
        font-size: 1rem;
        font-weight: bold;
    }

    &__icon {
        color: $primary;
        position: relative;
        padding: 0 1rem;
        svg {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto
        }
    }
}