.dark-subtitle {
    width: max-content;
    text-align: right;
    font-family: $font-family-he-v1;
    letter-spacing: 0.22px;
    color: black;
    font-weight: 600;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem 0.5rem 5rem;
    font-size: clamp(1rem, 4.3vw, 1.2rem);

    &__text-color-primary {
        color: $primary;
    }
    &__text-color-success {
        color: $success;
    }
    &__text-color-lucky {
        color: $lucky;
    }
    &__text-color-warning {
        color: $warning;
    }
    &__text-color-error {
        color: $error;
    }
    &__text-color-info {
        color: $info;
    }
    &__text-color-secondary {
        color: $secondary;
    }
    &__text-color-settings {
        color: $settings;
    }
}