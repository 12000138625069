.text-field-component {
    // margin: 1.4rem 0 1.2rem 0;
    display: block;
    height: max-content;
    color: rgb(37, 37, 37);
    border: 0.1px solid #9b9b9b;
    position: relative;
    width: max-content;
    border-radius: 5px;
    height: 2.55rem;
    max-width: 10rem;



    background-color: white;

    &__border-color-error {
        border-color: $error;
        .text-field-component__label {
            color: $error !important;
        }
    }
    &__border-color-primary {
        border-color: $primary;
        .text-field-component__label {
            color: $primary !important;
        }
    }
    &__border-color-success {
        border-color: $success;
        .text-field-component__label {
            color: $success !important;
        }
    }
    &__border-color-lucky {
        border-color: $lucky;
        .text-field-component__label {
            color: $lucky !important;
        }
    }
    &__border-color-secondary {
        border-color: $secondary;
        .text-field-component__label {
            color: $secondary !important;
        }
    }
    &__border-color-info {
        border-color: $info;
        .text-field-component__label {
            color: $info !important;
        }
    }
    &__border-color-warning {
        border-color: $warning;
        .text-field-component__label {
            color: $warning !important;
        }
    }
    &__border-color-settings {
        border-color: $settings;
        .text-field-component__label {
            color: $settings !important;
        }
    }
    
    &:hover {
        border:  0.1px solid rgb(100, 100, 100) !important;
    }
    border: 0.1px solid #9b9b9b;
    &__input-field {
        outline: none;
        border: none;
        height: 100%;
        width: 100%;
        padding-right: 0.75rem;
        text-align: right;
        font-size: 0.9rem;
        margin: 0;
        color: #646464;
        background: transparent;
    }
    .type-tel {
    }

    &__label, &__label-background {
        position: absolute;
        top: -1px;
        bottom: 0;
        right: 0.2rem;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: max-content;
        width: max-content;
        font-size: 0.95rem;
        padding: 2px 6px;
        color: rgb(37, 37, 37);
        cursor: text;
        transition: all 0.1s;
        z-index: 5;
    }
    .label-open {
        top: -3.56rem;
        right: -0.25rem;
        padding: 2px 5px;
        font-size: 12px;
        color: #3b3b3b;
        font-weight: bolder;
    }
    &__label-background {
        top: -3.5rem;
        right: 0.5rem;
        font-size: 12px;
        color: transparent;
        background-color: rgba(255, 255, 255, 0);
        z-index: 4;
        transition: all 0s;
    }
    .label-background-open {
        transition: all 0.1s;
    }

    // validate error 
    &__error-validate {
        color: $error;
        position: absolute;
        top: 3rem;
        right: 0.3rem;
        width: 100%;
        text-align: right;
        font-size: 1rem;
    }

    input[type="number"] {
        -webkit-appearance: textfield;
           -moz-appearance: textfield;
                appearance: textfield;
      }
      input[type=number]::-webkit-inner-spin-button, 
      input[type=number]::-webkit-outer-spin-button { 
        padding: 1.1rem 0.25rem;
        margin: 0 0 0 0.4rem;
      }
}

.disabled-text-field-component {
    opacity: 0.3;
}

.error-text-field-component {
    border: 0.1px solid $error;
}