.list-box-component {
    background-color: #E7EBF0;
    padding: 0.2rem;
    border-radius: 5px;
    height: 200px;
    width: 280px;
    overflow-y: hidden;
    font-family: $font-family_he_v1;
    position: absolute;
    top: 1rem;
    right: 4.5rem;
    margin: auto;
    z-index: 5;

    &__ul {
        overflow-y: scroll;
        height: 100%;
        box-shadow: 1px 2px 2px #a0a0a0;
        border-radius: 4px;
        padding: 0.3rem 0;

        &::-webkit-scrollbar {
            width: 5px;
          }
        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(129, 129, 129);
            border-radius: 10px;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background-color: #6d6d6d;
        }
    }

    &__li {
        background-color: white;
        padding: 0.3rem;
        border: 0.5px solid #E7EBF0;
        text-align: center;
        cursor: pointer;

        &:hover {
            background-color: #eff2f7;
        }
    }
}